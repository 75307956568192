import Web3 from "web3";
import { ERC20Contracts } from "../contracts/constants/contracts";
import { ContractLookup } from "../contracts/contracts.lookup";
import {store} from "../index";
let web3 = new Web3();
// @ts-ignore
export const NFTCreation = async (modelName,address,route) => {
    debugger
    web3 = store.getState().wallet.web3;
    let walletInfo = store.getState().wallet;
    let activeAddress = walletInfo.selected.address;
    const contractInfo = ContractLookup.find(contract => contract.contractName === ERC20Contracts.DUNKPLATFORM);
    const contractInfoNFT = ContractLookup.find(contract => contract.contractName === ERC20Contracts.DunkNFT);
    const contractNFT = new web3.eth.Contract(contractInfoNFT.contractAbi, contractInfoNFT?.contractAddress,{ from: activeAddress });
    if (web3.currentProvider) {    
        if (contractInfo) {
            // @ts-ignore
            const contract = new web3.eth.Contract(contractInfo.contractAbi, contractInfo?.contractAddress,{ from: activeAddress });
            try {
                const tx = await contract.methods.mintNFT(modelName,address,route).send({ gasPrice: 10000000000 });
                debugger
                const res = await contractNFT.getPastEvents("Transfer",{
                    fromBlock: tx.blockNumber,
                })
            tx.tokenId = +res[0]?.returnValues?.tokenId
            debugger
            return tx;
            } catch (error) {
                
                console.log(error)
                throw new Error(error);
            }
        }
    }
    else return 0;
}

export const updateTokenTier = async (tokenId,tier) => {
    web3 = store.getState().wallet.web3;
    let walletInfo = store.getState().wallet;
    let activeAddress = walletInfo.selected.address;
    const contractInfo = ContractLookup.find(contract => contract.contractName === ERC20Contracts.DUNKPLATFORM)
    
    if (web3.currentProvider) {    
        if (contractInfo) {
            // @ts-ignore
            const contract = new web3.eth.Contract(contractInfo.contractAbi, contractInfo?.contractAddress,{ from: activeAddress });
            try {
                const tx = await contract.methods.updateTokenTier(tokenId,tier).send();
            
            return tx;
            } catch (error) {
                
                console.log(error)
                throw new Error(error);
            }
        }
    }
    else return null;
}

export const safeTransferFrom = async (owner,to,tokenId) => {
    debugger
    web3 = store.getState().wallet.web3;
    let walletInfo = store.getState().wallet;
    let activeAddress = walletInfo.selected.address;
    const contractInfo = ContractLookup.find(contract => contract.contractName === ERC20Contracts.DunkNFT)
    debugger
    if (web3.currentProvider) {    
        if (contractInfo) {
            // @ts-ignore
            const contract = new web3.eth.Contract(contractInfo.contractAbi, contractInfo?.contractAddress,{ from: activeAddress });
            try {
                const tx = await contract.methods.safeTransferFrom(owner,to,tokenId).send()
            return tx;
            } catch (error) {
                
                console.log(error)
                throw new Error(error);
            }
        }
    }
    else throw new Error();;
}
export const ownerOf = async () => {
    debugger
    web3 = store.getState().wallet.web3;
    let walletInfo = store.getState().wallet;
    let activeAddress = walletInfo.selected.address;
    const contractInfo = ContractLookup.find(contract => contract.contractName === ERC20Contracts.DunkNFT)
    debugger
    if (web3.currentProvider) {    
        if (contractInfo) {
            // @ts-ignore
            const contract = new web3.eth.Contract(contractInfo.contractAbi, contractInfo?.contractAddress,{ from: activeAddress });
            try {
                const tx = await contract.methods.ownerOf(9).call()
            return tx;
            } catch (error) {
                console.log(error)
            }
        }
    }
    else throw new Error();;
}