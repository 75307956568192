import Web3 from "web3";
import {
  RESET_WALLET_DATA,
  SAVE_WALLET_DATA,
  SAVE_WEB3_DATA,
  SET_SELECTED_DATA,
  LOADING_BALANCE_PENDING,
} from "./WalletActionTypes";

export const loadingBalancePending = () => {
  return { type: LOADING_BALANCE_PENDING };
};
export const SaveWeb3InfoAction = (source, web3) => {
  return { type: SAVE_WEB3_DATA, web3, source, isConnected: true };
};
export const SetSelectedWalletAction = (wallet) => {
  return { type: SET_SELECTED_DATA, selected: wallet };
};

export const saveWalletsInfoAction = (wallets) => {
  return { type: SAVE_WALLET_DATA, wallets };
};

export const resetWalletsInfoAction = () => {
  let selected = {
    address: "",
    isConnected: false,
  };
  let web3 = new Web3();
  return {
    type: RESET_WALLET_DATA,
    wallets: [],
    selected,
    source:"",
    isConnected: false,
    web3,
  };
};

