import React, { useState, useMemo } from "react";
import styles from "./Dashboard.module.scss";
import { Table, Tag, Drawer, Tooltip } from "antd";
import Loader from "react-loader-spinner";
import useDashboard from "../../hooks/useDashboard";
import Images from "../_common/Images/Images";
import SneakerImgPicker from "./SneakerImgPicker";
import { ConditionTypeEnum, imgTypesEnum } from "../../constants";
import { color, edition, size,tier } from "../../constants/constants";

const Dashboard = () => {
  const {
    columns,
    data,
    updatingTier,
    isConfirming,
    isRejecting,
    isSubmitting,
    closeDrawer,
    drawer,
    formik,
    images,
    handleChange,
    handleFrontLeftImg,
    handleFrontRightImg,
  } = useDashboard();
  console.log(images);
  // select the row
  // const onClickRow = (record) => {
  //   return {
  //     onClick: () => {
  //       console.log(record);
  //     },
  //   };
  // };
  const arr = [
    "shipping",
    "delivery_complete",
    "inspection_process",
    "ready_to_enjoy",
    "approved",
    "rejected",
  ];

  // const widgets = useMemo(() => {
  //   let widgets = [];
  //   for (let i = 0; i < images.length; i += 2) {
  //     widgets.push(
  //       <SneakerImgPicker
  //         images={images}
  //         left={images.find((item) => item.includes(imgTypesEnum[i]))}
  //         right={images.find((item) => item.includes(imgTypesEnum[i + 1]))}
  //         heading="Sneaker Front image"
  //         handleChange={handleChange}
  //         // handleLeftImg={handleFrontLeftImg}
  //         // handleRightImg={handleFrontRightImg}
  //       />
  //     );
  //   }
  //   return widgets;
  // }, [images]);
  console.log("images array", images);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title}>Users Requests</div>
        {/* <div className={styles.divider} /> */}
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 10 }}
          scroll={{ x: true }}
          loading={updatingTier || isConfirming || isRejecting || isSubmitting ? true : false}
          // onRow={onClickRow}
        />
        <Drawer
          title="Edit User Request"
          width={500}
          onClose={closeDrawer}
          visible={drawer}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <div className={styles.editDrawer}>
            {/* {widgets.map((item) => item)} */}
            <SneakerImgPicker
              images={images}
              left={images[0]?.path}
              right={images[1]?.path}
              leftIndex={images[0]?.imgIndex}
              rightIndex={images[1]?.imgIndex}
              heading="Sneaker Front image"
              handleChange={handleChange}
            />
            <SneakerImgPicker
              images={images}
              left={images[2]?.path}
              right={images[3]?.path}
              leftIndex={images[2]?.imgIndex}
              rightIndex={images[3]?.imgIndex}
              heading="Sneaker Back image"
              handleChange={handleChange}
            />
            <SneakerImgPicker
              images={images}
              left={images[4]?.path}
              right={images[5]?.path}
              leftIndex={images[4]?.imgIndex}
              rightIndex={images[5]?.imgIndex}
              heading="Sneaker Left image"
              handleChange={handleChange}
            />
            <SneakerImgPicker
              images={images}
              left={images[6]?.path}
              right={images[7]?.path}
              leftIndex={images[6]?.imgIndex}
              rightIndex={images[7]?.imgIndex}
              heading="Sneaker Right image"
              handleChange={handleChange}
            />
            <SneakerImgPicker
              images={images}
              left={images[8]?.path}
              right={images[9]?.path}
              leftIndex={images[8]?.imgIndex}
              rightIndex={images[9]?.imgIndex}
              heading="Sneaker Bottom image"
              handleChange={handleChange}
            />
            {/* <Images images={images} handleChange={handleChange}/> */}
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.inputDiv}>
                <label htmlFor="fee">Model name </label>
                <input
                  type="text"
                  id="modelName"
                  {...formik.getFieldProps("modelName")}
                />
              </div>
              {formik.touched.modelName && formik.errors.modelName ? (
                <div className={styles.error}>{formik.errors.modelName}</div>
              ) : null}
              <div className={styles.inputDiv}>
                <label htmlFor="color">Color</label>
                <select
                label="color"
                name="color"
                id="color"
                {...formik.getFieldProps("color")}
              >
                <option value="">Select Color</option>
                {color.map((item) => (
                  <>
                    <option value={item}>{item}</option>
                  </>
                ))}
              </select>
              </div>
              {formik.touched.color && formik.errors.color ? (
                <div className={styles.error}>{formik.errors.color}</div>
              ) : null}
              <div className={styles.inputDiv}>
                <label htmlFor="edition">Edition</label>
                <select
                label="edition"
                name="edition"
                id="edition"
                {...formik.getFieldProps("edition")}
              >
                <option value="">Select Edition</option>
                {edition.map((item) => (
                  <>
                    <option value={item}>{item}</option>
                  </>
                ))}
              </select>
              </div>
              {formik.touched.edition && formik.errors.edition ? (
                <div className={styles.error}>{formik.errors.edition}</div>
              ) : null}

              <div className={styles.inputDiv}>
                <label htmlFor="size">Size</label>
                <select
                label="size"
                name="size"
                id="size"
                {...formik.getFieldProps("size")}
              >
                <option value="">Select Size</option>
                {size.map((item) => (
                  <>
                    <option value={item}>{item}</option>
                  </>
                ))}
              </select>
              </div>
              {formik.touched.size && formik.errors.size ? (
                <div className={styles.error}>{formik.errors.size}</div>
              ) : null}

              <div className={styles.inputDiv}>
                <label htmlFor="condition">Condition</label>
                <select
                  label="condition"
                  name="condition"
                  id="condition"
                  {...formik.getFieldProps("condition")}
                >
                  <option value="">Select condition</option>
                  <option value={ConditionTypeEnum.New}>
                    {ConditionTypeEnum.New}
                  </option>
                  <option value={ConditionTypeEnum.Worn}>
                    {ConditionTypeEnum.Worn}
                  </option>
                </select>
                {/* <input type="text" {...formik.getFieldProps("condition")} /> */}
              </div>
              {formik.touched.condition && formik.errors.condition ? (
                <div className={styles.error}>{formik.errors.condition}</div>
              ) : null}
              <div className={styles.inputDiv}>
                <label htmlFor="remarks">Remarks</label>
                <textarea {...formik.getFieldProps("remarks")} />
              </div>
              {formik.touched.remarks && formik.errors.remarks ? (
                <div className={styles.error}>{formik.errors.remarks}</div>
              ) : null}

              <button
                type="submit"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isSubmitting ? (
                  <Loader type="Bars" color="#ffffff" height={30} width={40} />
                ) : (
                  "Update"
                )}
              </button>
            </form>
          </div>
        </Drawer>
      </div>

      {/* <div className={styles.wrapper}>
        <div className={styles.divider} />
        <GenerateToken
          isLoading={false}
          title={"Generate token"}
          description={
            "To add Oracle Price contract address of specified synth in Price Feed Contract"
          }
          handelAddAddress={() => console.log("hello")}
          buttonText="ADD ADDRESS"
        />
      </div> */}
    </>
  );
};

export default Dashboard;
