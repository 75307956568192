import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";

import Header from "../../../components/_common/Header/Header";
import CoinManagement from "../../../components/CoinManagement/CoinManagement";
import DepositAddresses from "../../../components/DepositAddresses/DepositAddresses";
import Settings from "../../../components/Settings/Settings";
import Stats from "../../../components/Dashboard/Dashboard";
import Navigation from "../../../components/_common/Navigation/Navigation";
import logo from "../../../assets/images/sidebar-logo.svg";

import { Layout } from "antd";
const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isVerifying,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        isVerifying ? (
          <div />
        ) : isAuthenticated ? (
          <>
            <Layout
              style={{
                minHeight: "100vh",
              }}
            >
              <Layout.Sider
                style={{ minHeight: "100vh" }}
                trigger={null}
                collapsible
                collapsed={collapsed}
              >
                <div className="sidebarLogo">
                  <div
                    style={{
                      marginRight: collapsed ? "0px" : "10px",
                    }}
                  >
                    <img src={logo} alt="logo" />
                  </div>
                  <div
                    style={{
                      display: "block",
                      fontWeight: "500",
                      fontSize: "25px",
                      whiteSpace: "nowrap",
                      opacity: collapsed ? "0" : "1",
                      overflow: "hidden",
                    }}
                  >
                    DUNK
                  </div>
                </div>
                <Navigation />
              </Layout.Sider>
              <Layout className="siteLayout">
                <Header collapsed={collapsed} toggle={toggle} />
                <Layout.Content
                  className="siteLayoutBackground"
                  style={{
                    margin: "24px 16px 16px 16px",
                    padding: 24,
                    minHeight: 280,
                  }}
                >
                  <Component {...props} />
                </Layout.Content>
              </Layout>
            </Layout>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
