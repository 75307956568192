import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import styles from "../components/Users/Users.module.scss";
import instance from "../utils/helper/http.helper";

const useUsers = () => {
  const [data, setData] = useState([]);
  const [toggling, setToggling] = useState(false);
  const columns = [
    {
      title: "Sr#",
      key: "index",
      render: (text, record, index) => index,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text, record) => text,
      //   console.log(text)
    },
    {
      title: "Can Create",
      key: "canGenerateRequests",
      render: (text, record) => (
        <> {record.canGenerateRequests ? "true" : "false"}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <div className={styles.actionRow}>
          <div
            className={styles.primaryDiv}
            onClick={() => handleToggle(record._id, index)}
          >
            Toggle
          </div>
        </div>
      ),
    },
  ];
  const getUsers = async () => {
    return await instance.get(`/users/all`);
  };
  const handleToggle = async (id, index) => {
    setToggling(true);
    const res = await instance.patch(`/users/block-user/${id}`);
    // const res = await instance.get(`/users/all`);
    let temp = [...data];
    debugger;
    temp[index] = res.data.data;
    setData([...temp]);
    setToggling(false);
  };
  useEffect(() => {
    getUsers()
      .then((res) => {
        console.log(res);
        setData([...res.data.data]);
      })
      .catch((err) => console.log(err.response));
  }, []);
  return {
    columns,
    data,
    toggling,
  };
};

export default useUsers;
