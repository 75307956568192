import React, { useState } from "react";
import styles from "./Settings.module.scss";
import password from "../../assets/images/password-icon.svg";
import eye from "../../assets/images/show-eye.svg";
import { Drawer } from "antd";
import { useFormik } from "formik";
import { changePasswordSchema } from "../../validations/validations";

const Settings = () => {
  const [revealOldPassword, setRevealOldPassword] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealConfirmPassword, setRevealConfirmPassword] = useState(false);
  const [wrongOldPasswordError, setWrongOldPasswordError] = useState(false);

  const changePasswordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values, onSubmitProps) => {
      console.log("Change Password: ", values);
      onSubmitProps.resetForm();
    },
  });

  const [changePasswordDrawer, setChangePasswordDrawer] = useState(false);
  const showChangePasswordDrawer = () => {
    setChangePasswordDrawer(true);
  };
  const closeChangePasswordDrawer = () => {
    setChangePasswordDrawer(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Settings</div>
      <div className={styles.divider} />
      <div className={styles.settingsContainer}>
        <div className={styles.settingCard}>
          <div className={styles.settingInfo}>
            <div className={styles.settingInfoTop}>
              <div className={styles.settingIcon}>
                <img src={password} alt="password" />
              </div>
              <div className={styles.settingName}>
                Change your account password
              </div>
            </div>
          </div>
          <button
            onClick={showChangePasswordDrawer}
            className={styles.settingButton}
          >
            Change Password
          </button>
        </div>
      </div>
      <Drawer
        title="Change Password"
        width={500}
        onClose={closeChangePasswordDrawer}
        visible={changePasswordDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        className={styles.changePasswordDrawer}
      >
        <form
          className={styles.passwordModal}
          onSubmit={changePasswordFormik.handleSubmit}
        >
          <div className={styles.inputDiv}>
            <label>Old Password</label>
            <div className={styles.input}>
              <input
                type={revealOldPassword ? "text" : "password"}
                {...changePasswordFormik.getFieldProps("oldPassword")}
              />
              <div
                className={styles.inputIcon}
                onMouseDown={() => setRevealOldPassword(true)}
                onMouseUp={() => setRevealOldPassword(false)}
              >
                <img src={eye} alt="check" />
              </div>
            </div>
          </div>
          {changePasswordFormik.touched.oldPassword &&
          changePasswordFormik.errors.oldPassword ? (
            <div className={styles.passwordError}>
              {changePasswordFormik.errors.oldPassword}
            </div>
          ) : null}
          <div className={styles.inputDiv}>
            {/*// @ts-ignore*/}
            <label>Enter New Password</label>
            <div className={styles.input}>
              <input
                type={revealPassword ? "text" : "password"}
                {...changePasswordFormik.getFieldProps("confirmPassword")}
              />
              <div
                className={styles.inputIcon}
                onMouseDown={() => setRevealPassword(true)}
                onMouseUp={() => setRevealPassword(false)}
              >
                <img src={eye} alt="check" />
              </div>
            </div>
          </div>
          {changePasswordFormik.touched.password &&
          changePasswordFormik.errors.password ? (
            <div className={styles.passwordError}>
              {changePasswordFormik.errors.password}
            </div>
          ) : null}
          <div className={styles.inputDiv}>
            <label>Confirm Password</label>
            <div className={styles.input}>
              <input
                type={revealConfirmPassword ? "text" : "password"}
                {...changePasswordFormik.getFieldProps("password")}
              />
              <div
                className={styles.inputIcon}
                onMouseDown={() => setRevealConfirmPassword(true)}
                onMouseUp={() => setRevealConfirmPassword(false)}
              >
                <img src={eye} alt="check" />
              </div>
            </div>
          </div>
          {changePasswordFormik.touched.confirmPassword &&
          changePasswordFormik.errors.confirmPassword ? (
            <div className={styles.passwordError}>
              {changePasswordFormik.errors.confirmPassword}
            </div>
          ) : null}

          {wrongOldPasswordError ? (
            <div className={styles.passwordError}>Wrong old password!</div>
          ) : null}

          <button type="submit" className={styles.passwordButton}>
            SAVE
          </button>
        </form>
      </Drawer>
    </div>
  );
};

export default Settings;
