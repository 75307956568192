import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "../components/Dashboard/Dashboard.module.scss";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NFTCreation, updateTokenTier } from "../services/NFTCreation.service";
import instance from "../utils/helper/http.helper";
import { Select, Tag } from "antd";
import { imgTypesEnum } from "../constants";
import { tier } from "../constants/constants";

const useDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { address } = useSelector((state) => state.wallet.selected);
  const [drawer, setDrawer] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [images, setImages] = useState([]);
  const [updatingTier, setUpdatingTier] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [addressErr, setAddressErr] = useState("");
  const [data, setData] = useState([]);
  const { Option } = Select;
  const initialValues = {
    _id: "",
    color: "",
    condition: "",
    modelName: "",
    remarks: "",
    edition: "",
    size: "",
  };
  const [formState, setFormState] = useState(initialValues);
  const getRequests = async () => {
    return await instance.get(`/requests`);
  };
  const handleFrontLeftImg = async (e) => {
    let file = e;
    let newFileName = address + "-front-left." + file.name.split(".")[1];
    var updatedFile = new FormData();
    updatedFile.append(`files`, file, newFileName);
    // let images = imagesToSend;
    // images.push(updatedFile);
    // setImagesToSend(images);
  };
  const handleFrontRightImg = async (e) => {
    let file = e;
    let newFileName = address + "-front-right." + file.name.split(".")[1];
    var updatedFile = new FormData();
    updatedFile.append(`files`, file, newFileName);
    // let images = imagesToSend;
    // images.push(updatedFile);
    // setImagesToSend(images);
  };
  useEffect(() => {
    getRequests()
      .then((res) => {
        console.log(res);
        setData([...res.data.data]);
      })
      .catch((err) => console.log(err.response));
  }, []);
  const patchStatus = async (status, tokenId, id) => {
    try {
      const res = await instance.patch(`/requests/sync/${id}`, {
        status: status,
        tokenId: tokenId,
      });
      return res;
    } catch (error) {
      throw new Error({});
    }
  };
  const handleApprove = async (status, record) => {
    setIsConfirming(true);
    if (address) {
      debugger;
      try {
        const res2 = await NFTCreation(
          record.modelName,
          record.ownerAddress,
          `${process.env.REACT_APP_BASE_URL}/requests/`
        );
        debugger
        const res = await patchStatus(status, res2?.tokenId, record._id);
        debugger;
        alert.show("User request is accepted", { type: "success" });
        getRequests()
          .then((res) => {
            console.log(res);
            setData([...res.data.data]);
            setIsConfirming(false);
            setIsRejecting(false);
          })
          .catch((err) => console.log(err.response));
      } catch (error) {
        setIsConfirming(false);
        getRequests().then((res) => {
          console.log(res);
          setData([...res.data.data]);
        });
      }
    } else {
      setIsConfirming(false);
      alert.show("Please connect your wallet", { type: "error" });
      return;
    }
  };
  const handleReject = async (status, record) => {
    console.log();
    setIsRejecting(true);
    try {
      const res = await patchStatus(status, 0, record._id);
      getRequests().then((res) => {
        console.log(res);
        setData([...res.data.data]);
        alert.show("user request updated successfully", { type: "success" });
        setIsRejecting(false);
      });
      console.log(res);
    } catch (error) {
      setIsRejecting(false);
      alert.show("something went wrong.", { type: "error" });
    }
  };
  const updateStatus = async (status, record) => {
    switch (status) {
      case "approved":
        handleApprove(status, record);
        break;
      default:
        handleReject(status, record);
        break;
    }
  };

  const updateTier = async (tier, record) => {
    console.log(record.tokenId);
    debugger;
    setUpdatingTier(true);
    try {
      const res = await updateTokenTier(record.tokenId, tier);
      if (res.status) {
        const res = await instance.patch(`/requests/${record._id}`, {
          tier: tier,
        });
        console.log(res);
        setUpdatingTier(false);
        alert.show("Tier Updated Successfully", { type: "success" });
      } else {
        setUpdatingTier(false);
        alert.show("Something Went Wrong.", { type: "error" });
      }
    } catch (error) {
      setUpdatingTier(false);
      alert.show("Something Went Wrong.", { type: "error" });
    }
  };

  const showDrawer = (data) => {
    const formValues = {
      _id: data._id,
      color: data.color,
      condition: data.condition,
      modelName: data.modelName,
      remarks: data.remarks,
      edition: data.edition,
      size: data.size,
    };
    setImagesArray(data.image);
    console.log(formValues);
    setFormState((prevState) => ({
      ...prevState,
      ...formValues,
    }));
    setDrawer(true);
  };
  const closeDrawer = () => {
    setImagesArray([]);
    setImagesToUpload([]);
    setDrawer(false);
    setFormState(initialValues);
  };
  console.log(imagesToUpload);
  const updateUserRequest = async (values) => {
    setIsSubmitting(true);
    const { _id, ...rest } = values;
    // update image start
    debugger;
    let imgArray = [];
    if (imagesToUpload.length > 0) {
      for (let i = 0; i < imagesToUpload.length; i++) {
        const res = await instance.post("/upload", imagesToUpload[i].file);
        imgArray.push(res.data.data[0]);
      }
    }
    // update image end
    debugger;
    let temp = [...imagesArray];
    for (let i = 0; i < imagesToUpload.length; i++) {
      temp.splice(imagesToUpload[i].index, 1);
    }

    let res;
    if (imgArray) {
      debugger;
      res = await instance.patch(`/requests/${_id}`, {
        ...rest,
        image: [...imgArray, ...temp],
      });
    } else {
      debugger;
      res = await instance.patch(`/requests/${_id}`, {
        ...rest,
      });
    }
    if (res) {
      getRequests()
        .then((res) => {
          console.log(res);
          setData([...res.data.data]);
          setIsSubmitting(false);
          closeDrawer();
          alert.show("User request updated successfully", { type: "success" });
        })
        .catch((err) => setIsSubmitting(false));
    }
    console.log(res);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formState,
    validationSchema: Yup.object({
      modelName: Yup.string().required("Required").max(30),
      color: Yup.string().required("Required").max(30),
      condition: Yup.string().required("Required").max(50),
      edition: Yup.string().required("Required"),
      size: Yup.string().required("Required"),
      remarks: Yup.string().required("Required").max(350),
    }),
    // validationSchema: assetDetailsSchema,
    onSubmit: async (values, onSubmitProps) => {
      console.log("Edit Asset Details: ", values);
      updateUserRequest(values);
      onSubmitProps.resetForm();
    },
  });
  const status = [
    "shipping",
    "delivery_complete",
    "inspection_process",
    "approved",
    "rejected",
  ];
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <div style={{ width: "60px", height: "60px" }}>
          <img
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src={`${process.env.REACT_APP_BASE_URL}/requests/file/${record.image[0].folderName}/${record.image[0].name}`}
            alt=""
          />
        </div>

        //   console.log(text)
      ),
    },
    {
      title: "Address",
      dataIndex: "ownerAddress",
      key: "ownerAddress",
      render: (text, record) => text,
      //   console.log(text)
    },
    {
      title: "Model name",
      dataIndex: "modelName",
      key: "modelName",
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Lottery",
      key: "lottery",
      dataIndex: "lottery",
      render: (text, record) => {
        if (!record.lottery) {
          return (
            <Tag color={"blue"} key={record.lottery}>
              False
            </Tag>
          );
        }
        if (record.lottery) {
          return (
            <Tag color={"green"} key={record.lottery}>
              True
            </Tag>
          );
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className={styles.actionRow}>
          {record.status === "approved" ? null : (
            <div
              className={styles.primaryDiv}
              onClick={() => {
                showDrawer(record);
              }}
            >
              <EditOutlined className={styles.iconSize} />
            </div>
          )}
          <Select
            defaultValue={record.status}
            style={{ width: 120 }}
            onChange={(x) => updateStatus(x, record)}
          >
            {record.status === "approved" ? (
              <></>
            ) : (
              status
                .slice(status.indexOf(record.status), status.length)
                .map((x, y) => (
                  <Option key={y} value={x}>
                    {x}
                  </Option>
                ))
            )}
          </Select>
          {
            record.lottery && (
              <div
                style={{ padding: "5px", margin: "0 5px" }}
                className={styles.primaryDiv}
                onClick={() => {
                  history.push(`/participants/${record._id}`);
                }}
              >
                Participants
              </div>
            )
            // record.participants.length
          }
          {record.tokenId > 0 && (
            <Select
              defaultValue={record.tier}
              style={{ width: 120 }}
              onChange={(x) => updateTier(x, record)}
            >
              <option value="">Select tier</option>
              {tier.map((item) => (
                <>
                  <option value={item}>Tier {item}</option>
                </>
              ))}
            </Select>
          )}
          {/* <select>{status.map((x,y) => <option key={y} onClick={() => updateStatus(x, record._id)}>{x}</option>)}</select>; */}
          {/* <CheckOutlined className={styles.iconSize} /> */}

          {/* <div
            className={styles.rejectDiv}
            onClick={() => updateStatus("rejected", record._id)}
          >
            <CloseOutlined className={styles.iconSize} />
          </div> */}
        </div>
      ),
    },
  ];

  // image component

  const createPath = (img, string) => {
    console.log(img);
    return `${process.env.REACT_APP_BASE_URL}/requests/file/${img?.folderName}/${img?.name}`;
  };
  useEffect(() => {
    if (imagesArray.length > 0) {
      let temp = [];
      for (let index = 0; index < 10; index++) {
        const newImage = imagesArray.filter((item) =>
          item.name.includes(imgTypesEnum[index])
        );
        debugger;
        let currentIndex = imagesArray.findIndex((element, index) => {
          if (element.name === newImage[0]?.name) {
            return true;
          }
        });
        temp.push({ path: createPath(newImage[0]), imgIndex: currentIndex });
      }
      console.log(temp);
      setImages(temp);
    }
  }, [imagesArray]);

  const handleChange = (e, i, imagesArrayIndex) => {
    debugger;
    console.log(i);
    let { files } = e.target;
    const reader = new FileReader();
    reader.onload = function (event) {
      setImages((prev) => {
        let newImages = [...prev];
        newImages[i].path = event.target.result;
        return newImages;
      });
    };
    if (files[0]) {
      reader.readAsDataURL(files[0]);
      let temp = [...imagesToUpload];

      //
      let file = files[0];
      let imgName = imagesArray[imagesArrayIndex].name;

      console.log(imgName);
      console.log(file.name);
      let newFileName = imgName.split("--")[0] + "." + file.name.split(".")[1];
      var updatedFile = new FormData();
      updatedFile.append(`files`, file, newFileName);
      //
      const index = temp.findIndex((el) => el.index === i);
      if (index !== -1) {
        temp[index] = {
          index: imagesArrayIndex,
          file: updatedFile,
        };
      } else {
        temp.push({ index: imagesArrayIndex, file: updatedFile });
      }
      console.log(temp);
      setImagesToUpload([...temp]);
    }
  };
  console.log("iiiiiiiiiiiiiiiiiii", images);
  return {
    columns,
    data,
    updatingTier,
    isConfirming,
    isRejecting,
    isSubmitting,
    closeDrawer,
    drawer,
    formik,
    images,
    handleChange,
    handleFrontLeftImg,
    handleFrontRightImg,
  };
};

export default useDashboard;
