import React from "react";
import { Menu } from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  LogoutOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../store/actions/authActions";
import Users from "../../Users/Users";

const Navigation = () => {
  const dispatch = useDispatch();
  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
      <Menu.Item key="1" icon={<DashboardOutlined />}>
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        <Link to="/users">Users</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<GiftOutlined />}>
        <Link to="/lottery">Lottery</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="5"
        icon={<LogoutOutlined />}
        onClick={() => {
          dispatch(userLogout());
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default Navigation;
