export const brand = [
  "Nike",
  "Adidas",
  "Air Jordan",
  "Yeezy",
  "Balenciaga",
  "Reebok",
  "Converse",
  "Vans",
];
export const tier = [0, 1, 2];
export const edition = ["Special", "Collaboration", "Limited", "Digital"];

export const size = [
  "190",
  "195",
  "200",
  "205",
  "210",
  "215",
  "220",
  "225",
  "230",
  "235",
  "240",
  "245",
  "250",
  "255",
  "260",
  "265",
  "270",
  "275",
  "280",
  "285",
  "290",
  "295",
  "300",
  "305",
  "310",
];

export const color = [
  "Black",
  "White",
  "Gray",
  "Charcoal",
  "Red",
  "Pink",
  "Maroon",
  "Crimson",
  "Orange",
  "Yellow",
  "Lime",
  "Green",
  "Light Green",
  "Dark Green",
  "Khaki",
  "Blue",
  "Navy",
  "Sky Blue",
  "Turquoise",
  "Purple",
  "Violet",
];

export const condition = ["New", "Worn"];
