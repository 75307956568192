import React, { useState } from "react";
import { Layout } from "antd";
import styles from "./Header.module.scss";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { initializeWeb3 } from "../../../services/web3.service";
import wallet from "../../../assets/wallet-icon.svg";
import metamask from "../../../assets/Metamask.svg";
import DunkModal from "../../Modal/DunkModal";
import { web3Sources } from "../../../constants";

const Header = ({ collapsed, toggle }) => {
  const {
    selected: { address, isConnected },
  } = useSelector((state) => state.wallet);
  const [connectOpen, setConnectOpen] = useState(false);
  const handleConnectOpen = () => {
    if (isConnected) {
      return;
    }
    setConnectOpen(!connectOpen);
  };
  const connectToWallet = async (value) => {
    // props.onConnected();
    await initializeWeb3(value).catch((e) => {
      console.log(e);
    });
  };
  return (
    <Layout.Header className={styles.header} style={{ padding: 0 }}>
      {collapsed ? (
        <MenuUnfoldOutlined className={styles.trigger} onClick={toggle} />
      ) : (
        <MenuFoldOutlined className={styles.trigger} onClick={toggle} />
      )}
      <div className={styles.connect} onClick={handleConnectOpen}>
        <div className={styles.walletIcon}>
          <img src={wallet} alt="wallet" />
        </div>
        <div className={styles.text}>
          {address
            ? "Connected Wallet" + " (" + address.substring(0, 4) + ")"
            : "Connect Wallet"}
        </div>
      </div>
      <DunkModal isOpen={connectOpen} toggle={handleConnectOpen}>
        <div className={styles.connectModal}>
          <div className={styles.connectOption}>
            <div className={styles.provider}>
              <div className={styles.image}>
                <img src={metamask} alt="provider" />
              </div>
              <div className={styles.name}>Metamask</div>
            </div>
            <button
              className={styles.button}
              onClick={() => {
                handleConnectOpen();
                connectToWallet(web3Sources.Metamask);
              }}
            >
              {isConnected ? "Connected" : "Connect Now"}
            </button>
          </div>
          {/* <div className={styles.connectOption}>
         <div className={styles.provider}>
           <div className={styles.image}>
             <img src={ether} alt="provider" />
           </div>
           <div className={styles.name}>My Ether Wallet</div>
         </div>
         <button className={styles.button}>Connect Now</button>
       </div> */}
        </div>
      </DunkModal>
    </Layout.Header>
  );
};

export default Header;
