import React from "react";
import styles from "./Users.module.scss";
import { Table, Tag, Drawer, Tooltip } from "antd";
import useUsers from "../../hooks/useUsers";

export default function Users() {
  const { columns, data, toggling } = useUsers();
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Users</div>
      {/* <div className={styles.divider} /> */}
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 10 }}
        scroll={{ x: true }}
        loading={toggling ? true : false}
        // onRow={onClickRow}
      />
    </div>
  );
}
