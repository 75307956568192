import React, { useState } from "react";
import styles from "./Login.module.scss";
import logo from "../../assets/images/sidebar-logo.svg";
import eye from "../../assets/images/show-eye.svg";
import metamask from "../../assets/Metamask.svg";
import wallet from "../../assets/wallet-icon.svg";
import { useFormik } from "formik";
import { userLoginSchema } from "../../validations/validations";
import { useDispatch, useSelector } from "react-redux";
import { userLogIn } from "../../store/actions/authActions";
import { initializeWeb3 } from "../../services/web3.service";
import DunkModal from "../Modal/DunkModal";
import { web3Sources } from "../../constants";

const Login = () => {
  const dispatch = useDispatch();
  const [reveal, setReveal] = useState(false);
  const {
    selected: { address, isConnected },
  } = useSelector((state) => state.wallet);
  const loginError = useSelector((state) => state.auth.loginError);
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: userLoginSchema,
    onSubmit: async (values, onSubmitProps) => {
      if (address && values.password) {
        console.log("Login Form Values: ", values);
        dispatch(userLogIn(address, values.password));
        onSubmitProps.resetForm();
      }
      else{
        console.log("returnig")
      }
    },
  });

  // const [connectOpen, setConnectOpen] = useState(false);
  const handleConnectOpen = () => {
    if (isConnected) {
      return;
    }
    connectToWallet(web3Sources.Metamask);
  };
  const connectToWallet = async (value) => {
    await initializeWeb3(value).catch((e) => {
      console.log(e);
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.login}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          {/* <div className={styles.logoText}>DUNK</div> */}
          <form onSubmit={formik.handleSubmit}>
            {/*  */}
            <div className={styles.connect} onClick={handleConnectOpen}>
              <div className={styles.walletIcon}>
                <img src={wallet} alt="wallet" />
              </div>
              <div className={styles.text}>
                {address
                  ? "Connected Wallet" + " (" + address.substring(0, 4) + ")"
                  : "Connect Wallet"}
              </div>
            </div>
            {/*  */}
            <div className={styles.inputDiv}>
              <label htmlFor="password">Password</label>
              <div className={styles.input}>
                <input
                  type={reveal ? "text" : "password"}
                  {...formik.getFieldProps("password")}
                />
                <div
                  className={styles.inputIcon}
                  onMouseDown={() => setReveal(true)}
                  onMouseUp={() => setReveal(false)}
                >
                  <img src={eye} alt="check" />
                </div>
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className={styles.error}>{formik.errors.password}</div>
            ) : null}
            {loginError && (
              <div className={styles.error}>Wrong credentials!</div>
            )}
            {/* {!isConnected && (
              <div className={styles.error}>Connect your wallet first.</div>
            )} */}
            <button type="submit" className={styles.passwordButton}>
              Login
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
