// import { act } from "react-dom/test-utils";
import Web3 from "web3";
import {
  RESET_WALLET_DATA,
  SAVE_WALLET_DATA,
  SAVE_WEB3_DATA,
  SET_SELECTED_DATA,
} from "../actions/WalletActionTypes";

const initialState = {
  web3: new Web3(),
  source:"",
  selected: {
    address: "",
    isConnected:false,
  },
  wallets: [],
  isConnected: false,
};

export function walletReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SAVE_WEB3_DATA:
      return {
        ...state,
        web3: action.web3,
        source: action.source,
        isConnected: action.isConnected,
      };

    case SET_SELECTED_DATA:
      return {
        ...state,
        selected: action.selected,
      };
    case SAVE_WALLET_DATA:
      return {
        ...state,
        wallets: action.wallets,
      };
    case RESET_WALLET_DATA:
      return {
        ...state,
        web3: action.web3,
        source: action.source,
        isConnected: action.isConnected,
        wallets: action.wallets,
        selected: action.selected,
      };

    default:
      return state;
  }
}
