import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./LotteryToken.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import SneakerImgPicker from "./SneakerImgPicker";
import instance from "../../utils/helper/http.helper";
import { ConditionTypeEnum } from "../../constants";
import {
  brand,
  edition,
  size,
  color,
  condition,
} from "../../constants/constants";

function LotteryToken() {
  const alert = useAlert();
  const {
    selected: { address, isConnected },
  } = useSelector((state) => state.wallet);
  const initialState = {
    brand: "",
    condition: "",
    modelName: "",
    color: "",
    remarks: "",
    edition: "",
    size: "",
    retailPrice:0,
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [listener, setListener] = useState(false);
  const [imagesToSend, setImagesToSend] = useState([]);
  const [state, setState] = useState(initialState);
  
  const handleSubmit = async (values) => {
    console.log(values.retailPrice)
    if (isConnected) {
      debugger;
      if (imagesToSend.length === 10) {
        try {
          debugger;

          // form.append("files", imagesToSend);
          let form = new FormData();
          let imgArray = [];
          for (let i = 0; i < imagesToSend.length; i++) {
            const res = await instance.post(
              "/upload",
              imagesToSend[i].fileToSend
            );
            imgArray.push(res.data.data[0]);
          }
          if (imgArray.length > 0) {
            const payload = {
              color: values.color,
              condition: values.condition,
              brandName: values.brand,
              modelName: values.modelName,
              remarks: values.remarks,
              edition: values.edition,
              size: values.size,
              price: 0,
              ownerAddress: address,
              tier: 0,
              retailPrice:+values.retailPrice,
              lottery:true,
              image: imgArray,
            };
            const res2 = await instance.post("/requests/lottery", payload);
            debugger;
            if (res2.data.success) {
              setListener(!listener);
              setState(initialState);
              setImagesToSend([]);
              setIsSubmitting(false);
              alert.show("Request created Successfully", { type: "success" });
              formik.resetForm({ initialState });
            }
            console.log(res2);
          }
        } catch (error) {
          setIsSubmitting(false);
          if (
            error?.response?.data?.message.includes(
              "E11000 duplicate key error collection"
            )
          ) {
            alert.show("Model name already exist. ", { type: "error" });
          } else {
            alert.show("Something went wrong.", { type: "error" });
          }
        }
      } else {
        alert.show("Please choose all images first", { type: "error" });
        setIsSubmitting(false);
        return;
      }
    } else {
      alert.show("Please connect your wallet", { type: "error" });
      setIsSubmitting(false);
      return;
    }
  };
  console.log(state);

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: Yup.object({
      retailPrice:Yup.number().required("Required"),
      brand: Yup.string().required("Required").max(30),
      modelName: Yup.string().required("Required").max(30),
      color: Yup.string().required("Required").max(30),
      condition: Yup.string()
        .oneOf(
          [ConditionTypeEnum.New, ConditionTypeEnum.Worn],
          "Invalid conditione"
        )
        .required("Required"),
      remarks: Yup.string().required("Required").max(350),
      edition: Yup.string().required("Required"),
      size: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(imagesToSend);
      setIsSubmitting(true);
      handleSubmit(values);
      // handleSubmit(values);
    },
  });
  const handleLeftImg = async (e, side) => {
    let images = imagesToSend;
    if (e) {
      let file = e;
      let newFileName = address + side + file.name.split(".")[1];
      // let newFileName = address + "-front-left." + file.name.split(".")[1];
      var updatedFile = new FormData();
      updatedFile.append(`files`, file, newFileName);
      images.push({ side: side, fileToSend: updatedFile });
      setImagesToSend(images);
    } else {
      let index = imagesToSend.findIndex((item) => item.side === side);
      console.log(index);
      if (index === -1) {
        setImagesToSend(images);
      } else {
        images.splice(index, 1);
        setImagesToSend(images);
      }
    }
  };
  const handleRightImg = async (e, side) => {
    debugger;
    let images = imagesToSend;
    if (e) {
      let file = e;
      let newFileName = address + side + file.name.split(".")[1];
      // let newFileName = address + "-front-right." + file.name.split(".")[1];
      var updatedFile = new FormData();
      updatedFile.append(`files`, file, newFileName);
      images.push({ side: side, fileToSend: updatedFile });
      setImagesToSend(images);
    } else {
      let index = imagesToSend.findIndex((item) => item.side === side);
      console.log(index);
      if (index === -1) {
        setImagesToSend(images);
      } else {
        images.splice(index, 1);
        setImagesToSend(images);
      }
    }
  };
  // console.log(imagesToSend);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1>Request LotteryToken</h1>
        <div className={styles.features}>
          Please take pictures as clearly as possible. It will make your
          LotteryToken process faster.
        </div>
        {/* <div className={styles.imgRow}>
          <img src="/assets/Image 1.svg" className={styles.passwordImg} />
          <img src="/assets/Image 2.svg" className={styles.passwordImg} />
          <img src="/assets/Image 3.svg" className={styles.passwordImg} />
          <img src="/assets/Image 4.svg" className={styles.passwordImg} />
          <img src="/assets/Image 5.svg" className={styles.passwordImg} />
          <img src="/assets/Image 6.svg" className={styles.passwordImg} />
        </div> */}
        <div className={styles.row}>
          <SneakerImgPicker
            listener={listener}
            heading="Sneaker Front image"
            leftSide="-front-left."
            rightSide="-front-right."
            handleLeftImg={handleLeftImg}
            handleRightImg={handleRightImg}
          />
          <SneakerImgPicker
            listener={listener}
            heading="Sneaker Back image"
            leftSide="-back-left."
            rightSide="-back-right."
            handleLeftImg={handleLeftImg}
            handleRightImg={handleRightImg}
          />
          <SneakerImgPicker
            listener={listener}
            heading="Sneaker Left image"
            leftSide="-left-left."
            rightSide="-left-right."
            handleLeftImg={handleLeftImg}
            handleRightImg={handleRightImg}
          />
          <SneakerImgPicker
            listener={listener}
            heading="Sneaker Right image"
            leftSide="-right-left."
            rightSide="-right-right."
            handleLeftImg={handleLeftImg}
            handleRightImg={handleRightImg}
          />
          <SneakerImgPicker
            listener={listener}
            heading="Sneaker Bottom image"
            leftSide="-bottom-left."
            rightSide="-bottom-right."
            handleLeftImg={handleLeftImg}
            handleRightImg={handleRightImg}
          />
        </div>
        <div className={styles.formRow}>
          {/*  */}
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.input_container}>
              <div className={styles.inputDiv}>
                <label htmlFor="modelName">Model-Name</label>
                <input
                  placeholder="Model Name"
                  value={state.color}
                  name="modelName"
                  id="modelName"
                  type="text"
                  {...formik.getFieldProps("modelName")}
                />
                {formik.touched.modelName && formik.errors.modelName ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.modelName}
                  </div>
                ) : null}
              </div>
              <div className={styles.inputDiv}>
                <label htmlFor="retailPrice">Retail-Price</label>
                <input
                  placeholder="Model Name"
                  name="retailPrice"
                  id="retailPrice"
                  type="text"
                  {...formik.getFieldProps("retailPrice")}
                />
                {formik.touched.retailPrice && formik.errors.retailPrice ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.retailPrice}
                  </div>
                ) : null}
              </div>
              
              {/* <div className={styles.inputDiv}>
                <label htmlFor="brand">Brand</label>
                <input
                  placeholder="Brand"
                  value={state.modelName}
                  name="brand"
                  id="brand"
                  type="text"
                  {...formik.getFieldProps("brand")}
                />
                {formik.touched.brand && formik.errors.brand ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.brand}
                  </div>
                ) : null}
              </div> */}

              <div className={styles.inputDiv}>
                <label htmlFor="brand">Brand</label>
                <select
                  label="brand"
                  value={state.remarks}
                  name="brand"
                  id="brand"
                  {...formik.getFieldProps("brand")}
                >
                  <option value="">Select Brand</option>
                  {brand.map((item) => (
                    <>
                      <option value={item}>{item}</option>
                    </>
                  ))}
                </select>
                {/* <input
                    placeholder="Condition"
                    value={state.remarks}
                    name="condition"
                    id="condition"
                    type="text"
                    {...formik.getFieldProps("condition")}
                  /> */}
                {formik.touched.brand && formik.errors.brand ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.brand}
                  </div>
                ) : null}
              </div>

              <div className={styles.inputDiv}>
                <label htmlFor="edition">Edition</label>
                <select
                  label="edition"
                  value={state.remarks}
                  name="edition"
                  id="edition"
                  {...formik.getFieldProps("edition")}
                >
                  <option value="">Select Edition</option>
                  {edition.map((item) => (
                    <>
                      <option value={item}>{item}</option>
                    </>
                  ))}
                </select>
                {/* <input
                  placeholder="Condition"
                  value={state.remarks}
                  name="condition"
                  id="condition"
                  type="text"
                  {...formik.getFieldProps("condition")}
                /> */}
                {formik.touched.edition && formik.errors.edition ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.edition}
                  </div>
                ) : null}
              </div>
              {/* <div className={styles.inputDiv}>
                <label htmlFor="brand">Edition</label>
                <input
                  placeholder="Edition"
                  value={state.modelName}
                  name="brand"
                  id="brand"
                  type="text"
                  {...formik.getFieldProps("brand")}
                />
                {formik.touched.brand && formik.errors.brand ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.brand}
                  </div>
                ) : null}
              </div> */}
              <div className={styles.inputDiv}>
                <label htmlFor="size">Size</label>
                <select
                  label="size"
                  value={state.remarks}
                  name="size"
                  id="size"
                  {...formik.getFieldProps("size")}
                >
                  <option value="">Select Size</option>
                  {size.map((item) => (
                    <>
                      <option value={item}>{item}</option>
                    </>
                  ))}
                </select>
                {/* <input
                  placeholder="Condition"
                  value={state.remarks}
                  name="condition"
                  id="condition"
                  type="text"
                  {...formik.getFieldProps("condition")}
                /> */}
                {formik.touched.size && formik.errors.size ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.size}
                  </div>
                ) : null}
              </div>

              {/* <div className={styles.inputDiv}>
                <input
                  placeholder="Color"
                  value={state.condition}
                  name="color"
                  id="color"
                  type="text"
                  {...formik.getFieldProps("color")}
                />
                {formik.touched.color && formik.errors.color ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.color}
                  </div>
                ) : null}
              </div> */}

              <div className={styles.inputDiv}>
                <label htmlFor="Color">Color</label>
                <select
                  label="color"
                  value={state.color}
                  name="color"
                  id="color"
                  {...formik.getFieldProps("color")}
                >
                  <option value="">Select Color</option>
                  {color.map((item) => (
                    <>
                      <option value={item}>{item}</option>
                    </>
                  ))}
                </select>
                {/* <input
                  placeholder="Condition"
                  value={state.remarks}
                  name="condition"
                  id="condition"
                  type="text"
                  {...formik.getFieldProps("condition")}
                /> */}
                {formik.touched.color && formik.errors.color ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.color}
                  </div>
                ) : null}
              </div>

              <div className={styles.inputDiv}>
                <label htmlFor="condition">Condition</label>
                <select
                  label="condition"
                  value={state.remarks}
                  name="condition"
                  id="condition"
                  {...formik.getFieldProps("condition")}
                >
                  <option value="">Select condition</option>
                  <option value={ConditionTypeEnum.New}>
                    {ConditionTypeEnum.New}
                  </option>
                  <option value={ConditionTypeEnum.Worn}>
                    {ConditionTypeEnum.Worn}
                  </option>
                </select>
                {/* <input
                  placeholder="Condition"
                  value={state.remarks}
                  name="condition"
                  id="condition"
                  type="text"
                  {...formik.getFieldProps("condition")}
                /> */}
                {formik.touched.condition && formik.errors.condition ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.condition}
                  </div>
                ) : null}
              </div>
              <div className={styles.textAreaDiv}>
                <label htmlFor="description">Description</label>
                <textarea
                  placeholder="Please describe the model"
                  {...formik.getFieldProps("remarks")}
                />
                {formik.touched.remarks && formik.errors.remarks ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.remarks}
                  </div>
                ) : null}
              </div>
            </div>

            <div className={styles.btn_container}>
              <button className={styles.button} type="submit">
                {isSubmitting ? (
                  <Loader type="Bars" color="black" height={30} width={40} />
                ) : (
                  <>
                    Request {">"}
                    {/* <img alt="img" src="../../assets/Path 429.svg" /> */}
                  </>
                )}
              </button>
            </div>
          </form>
          {/*  */}
          {/* <div className={styles.noteDiv}>
            <div className={styles.address}>DUNK Warehouse Address :</div>
            <div>
              * Please write your wallet address on the package for fast
              processing
            </div>
          </div> */}
        </div>{" "}
      </div>
    </div>
  );
}
export default LotteryToken;
