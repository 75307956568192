import React, { useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./components/_common/PrivateRoute/PrivateRoute";
import PublicRoute from "./components/_common/PublicRoute/PublicRoute";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Users from "./components/Users/Users";
import LotteryToken from "./components/LotteryToken/LotteryToken";
import Participants from "./components/Participants/Participants";

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isVerifying = useSelector((state) => state.auth.isVerifying);


  return (
    <>
      <Router>
        <Switch>
          <PublicRoute
            exact
            path="/"
            component={Login}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            component={Dashboard}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <PrivateRoute
            exact
            path="/users"
            component={Users}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <PrivateRoute
            exact
            path="/lottery"
            component={LotteryToken}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <PrivateRoute
            exact
            path="/participants/:id"
            component={Participants}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
        </Switch>
      </Router>
    </>
  );
};

export default App;
