import btc from '../assets/coins/btc.svg';
import eth from '../assets/coins/eth.svg';
import ltc from '../assets/coins/ltc.svg';
import dash from '../assets/coins/dash.svg';
import doge from '../assets/coins/doge.svg';
import weenus from '../assets/coins/weenus.svg';

const coinIcons = {
  btc,
  eth,
  ltc,
  dash,
  doge,
  weenus,
};

export default coinIcons;
