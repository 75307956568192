import * as yup from "yup";

export const userLoginSchema = yup.object().shape({
  password: yup.string().required("Password field is required"),
});

export const assetDetailsSchema = yup.object().shape({
  description: yup.string().required("Field is required"),
  fee: yup.string().required("Field is required"),
  address: yup.string().required("Field is required"),
  isFixed: yup.boolean(),
  fixedRate: yup.string().when("isFixed", {
    is: true,
    then: yup.string().required("Field is required"),
  }),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old Password field is required"),
  password: yup
    .string()
    .min(8, "Min Password Length is 8")
    .required("Password field is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password don't match!")
    .required("Confirm password field is required"),
});
