import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./custom-antd.scss";
import "./global.scss";
import { Provider as AlertProvider, positions, transitions } from "react-alert";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./store/configureStore";
import AlertTemplate from "./components/AlertTemplate/AlertTemplate";

export const store = configureStore();
const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...options}>
      <App />
      </AlertProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
