import React from "react";
import styles from "./DepositAddresses.module.scss";
import coinIcons from "../../constants/coinIcons";
import { Table } from "antd";

const DepositAddresses = () => {
  const columns = [
    {
      title: "Blockchain Network",
      dataIndex: "network",
      key: "network",
      filters: [
        {
          text: "Bitcoin",
          value: "Bitcoin",
        },
        {
          text: "Ethereum",
          value: "Ethereum",
        },
      ],
      onFilter: (value, record) => record.network.indexOf(value) === 0,
    },
    {
      title: "Coin Icon",
      dataIndex: "icon",
      key: "icon",
      render: (text, record) => {
        return <img src={coinIcons[record.symbol]} alt={record.coin} />;
      },
    },
    {
      title: "Coin Name",
      dataIndex: "coin",
      key: "coin",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      render: (text, record) => {
        return record.symbol.toUpperCase();
      },
    },
    {
      title: "Wallet Address",
      dataIndex: "address",
      key: "address",
    },
  ];
  const data = [
    {
      key: "1",
      coin: "Bitcoin",
      symbol: "btc",
      network: "Bitcoin",
      address: "C87FgHWkhq8xYxEQ1QjLDHv4f9yrHrQTX5",
    },
    {
      key: "2",
      coin: "Ethereum",
      symbol: "eth",
      network: "Ethereum",
      address: "C87FgHWkhq8xYxEQ1QjLDHv4f9yrHrQTX5",
    },
    {
      key: "3",
      coin: "Litecoin",
      symbol: "ltc",
      network: "Bitcoin",
      address: "C87FgHWkhq8xYxEQ1QjLDHv4f9yrHrQTX5",
    },
    {
      key: "4",
      coin: "Doge",
      symbol: "doge",
      network: "Bitcoin",
      address: "C87FgHWkhq8xYxEQ1QjLDHv4f9yrHrQTX5",
    },
    {
      key: "5",
      coin: "Dash",
      symbol: "dash",
      network: "Bitcoin",
      address: "C87FgHWkhq8xYxEQ1QjLDHv4f9yrHrQTX5",
    },
    {
      key: "6",
      coin: "Weenus",
      symbol: "weenus",
      network: "Ethereum",
      address: "C87FgHWkhq8xYxEQ1QjLDHv4f9yrHrQTX5",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>User Deposit Addresses</div>
      <div className={styles.divider} />
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 10 }}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default DepositAddresses;
