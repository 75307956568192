import React, { useState, useEffect } from "react";
import styles from "./Images.module.scss";

const Images = ({ images,handleChange }) => {
  

  console.log(images);
  return (
    <>
    <div className={styles.flexRow}>
      {images.map((image, index) => (
        <div className={styles.imageDiv} key={index}>
          <img src={image} htmlFor={`actual-btn${index}`} />
          <input
            type="file"
            id={`actual-btn${index}`}
            onChange={(e) => handleChange(e, index)}
          />
          <div className={styles.labelDiv} key={index}>
            <label htmlFor={`actual-btn${index}`}>Change</label>
          </div>
        </div>
      ))}
    </div>
      </>
  );
};
export default Images;
