import React, { useState, useEffect } from "react";
import styles from "./Participants.module.scss";
import { useAlert } from "react-alert";
import { Table, Tag, Drawer, Tooltip } from "antd";
import { useParams } from "react-router";
import instance from "../../utils/helper/http.helper";
import { ownerOf, safeTransferFrom } from "../../services/NFTCreation.service";
import { useHistory } from "react-router-dom";
const Participants = () => {
  const history = useHistory();
  const alert = useAlert();
  const { id } = useParams();
  const [participantsData, setParticipantsData] = useState([]);
  const [data, setData] = useState({});
  const [isTransfering, setIsTransfering] = useState(false);
  const handleTransfer = async (record) => {
    debugger;
    try {
      setIsTransfering(true);
      const res = await safeTransferFrom(
        data.ownerAddress,
        record.address,
        data.tokenId
      );
      if (res?.status) {
        debugger;
        await instance.patch(`/requests/admin/auction/${id}`, {
          lottery: false,
          retailPrice: 0,
          participants: [],
          ownerAddress: record.address,
        });
        history.push(`/dashboard`);
        alert.show("Token Transfered Successfully.", { type: "success" });
        setIsTransfering(false);
      }
    } catch (error) {
      debugger;
      console.log(error);
      alert.show("Something Went Wrong.", { type: "error" });
      setIsTransfering(false);
    }
  };
  const getApproved = async () => {
    try {
      const res2 = await instance.get(`/requests/${id}`);
      setData(res2.data.data);
      setParticipantsData([...res2.data.data.participants]);
    } catch (error) {
      console.log(error);
      setParticipantsData([]);
    }
  };
  useEffect(() => {
    getApproved();
    // ownerOf().then((res) => {
    //   console.log(res);
    // });
  }, []);
  const columns = [
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text, record) => text,
      //   console.log(text)
    },
    {
      title: "Dunk",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className={styles.actionRow}>
          <div
            className={styles.primaryDiv}
            onClick={() => {
              handleTransfer(record);
            }}
          >
            Transfer
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title}>Participants List</div>
        {/* <div className={styles.divider} /> */}
        <Table
          columns={columns}
          dataSource={participantsData}
          pagination={{ pageSize: 10 }}
          scroll={{ x: true }}
          loading={isTransfering ? true : false}
          // onRow={onClickRow}
        />
      </div>
    </>
  );
};

export default Participants;
