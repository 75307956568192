import axios from "axios";
import { store } from "../../index";
import instance from "../../utils/helper/http.helper";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
} from "./actionTypes";
import {resetWalletsInfoAction} from "./WalletActions"

const API_URL = "https://api.dunk.exchange";
const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (token) => {
  return {
    type: LOGIN_SUCCESS,
    token,
  };
};

const loginError = () => {
  return {
    type: LOGIN_FAILURE,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

export const userLogIn = (username, password) => (dispatch) => {
  // User Log In Logic
  console.log("username/ password", username, password);
  // dispatch(requestLogin());
  // localStorage.setItem("accessToken", username);
  //     dispatch(receiveLogin());
  debugger
  instance
    .post(`/auth/login`, {
      address: username,
      password: password,
    })
    .then((response) => {
      localStorage.setItem("accessToken", response.data.data.access_token);
      dispatch(receiveLogin(response.data.data.access_token));
    })
    .catch((error) => {
      console.log("Login Error: ", error);
      dispatch(loginError());
    });
};

export const userLogout = () => async (dispatch) => {
  // User Log Out Logic
  await dispatch(requestLogout());
  await localStorage.clear();
  await dispatch(receiveLogout());
  await dispatch(resetWalletsInfoAction());
  // window.location.reload();
};
export const getToken=()=>{
  return localStorage.getItem("accessToken")
}
export const verifyAuth = () => (dispatch) => {
  // Verify User on Reload Logic
  // dispatch(verifyRequest());
  // if (localStorage.getItem("accessToken")) {
  //   dispatch(receiveLogin());
  // }
  // dispatch(verifySuccess());
  dispatch(userLogout())
};
