import React, { useEffect } from "react";
import styles from "./Dashboard.module.scss";
import ImageUploading from "react-images-uploading";

export default function SneakerImgPicker({
  images,
  left,
  right,
  leftIndex,
  rightIndex,
  heading,
  handleChange,
}) {
  const [leftImages, setLeftImages] = React.useState([]);
  const [rightImages, setRightImages] = React.useState([]);
  const onLeftChange = (imageList, addUpdateIndex) => {
    // data for submit
    debugger;
    console.log(imageList, addUpdateIndex);
    const result = imageList.map((result) => result.file);
    console.log(result);
    console.log(imageList);
    setLeftImages(imageList);
    // handleLeftImg(imageList[0].file);
  };
  const getImgIndex = (img) => {
    const index = images.findIndex((el) => el?.path === img);
    return index;
  };
  const onRightChange = (imageList, addUpdateIndex) => {
    // data for submit
    debugger;
    console.log(imageList, addUpdateIndex);
    const result = imageList.map((result) => result.file);
    console.log(result);
    console.log(imageList);
    setRightImages(imageList);
    // handleRightImg(imageList[0].file);
  };
  console.log(images);
  return (
    <div className={styles.sneakerDiv}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.flexRow}>
        <div className={styles.changeSneaker}>
          <div className={styles.previewDiv}>
            <div className={styles.side}>L</div>
            <img src={left} alt="" width="100" />
            <input
              type="file"
              id={`actual-btn-l${leftIndex}`}
              // onChange={(e) => handleLeftImg(e, index)}
              onChange={(e) => {
                debugger
                handleChange(e, getImgIndex(left), leftIndex)
              }}
            />
            <div className={styles.labelDiv}>
              <label htmlFor={`actual-btn-l${leftIndex}`}>Change</label>
            </div>
          </div>
          {/* <div className={styles.labelDiv}>
            <label htmlFor="actual-btn-l">Change</label>
          </div> */}
        </div>
        {/*  */}
        <div className={styles.separator}></div>
        {/*  */}
        <div className={styles.changeSneaker}>
          <div className={styles.previewDiv}>
            <div className={styles.side}>R</div>
            <img src={right} alt="" width="100" />
            <input
              type="file"
              id={`actual-btn-r${rightIndex}`}
              // onChange={(e) => handleLeftImg(e, index)}
              onChange={(e) => handleChange(e, getImgIndex(right), rightIndex)}
            />
            <div className={styles.labelDiv}>
              <label htmlFor={`actual-btn-r${rightIndex}`}>Change</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
