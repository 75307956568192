import React, { useEffect } from "react";
import styles from "./LotteryToken.module.scss";
import ImageUploading from "react-images-uploading";

export default function SneakerImgPicker({
  listener,
  heading,
  leftSide,
  rightSide,
  handleLeftImg,
  handleRightImg,
}) {
  const [leftImages, setLeftImages] = React.useState([]);
  const [rightImages, setRightImages] = React.useState([]);
  const onLeftChange = (imageList, addUpdateIndex) => {
    // data for submit
    debugger;
    console.log(imageList, addUpdateIndex);
    const result = imageList.map((result) => result.file);
    console.log(result);
    console.log(imageList);
    setLeftImages(imageList);
    handleLeftImg(imageList[0]?.file,leftSide);
  };
  const onRightChange = (imageList, addUpdateIndex) => {
    // data for submit
    debugger;
    console.log(imageList, addUpdateIndex);
    const result = imageList.map((result) => result.file);
    console.log(result);
    console.log(imageList);
    setRightImages(imageList);
    handleRightImg(imageList[0]?.file,rightSide);
  };
  useEffect(() => {
    setLeftImages([]);
    setRightImages([]);
  }, [listener]);
  return (
    <div className={styles.sneakerDiv}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.information}>
        Drag or choose your file to upload
      </div>
      <div className={styles.flexRow}>
        {/*  */}
        <ImageUploading
          multiple
          value={leftImages}
          onChange={onLeftChange}
          maxNumber={1}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className={styles.previewDiv}>
              <div className={styles.side}>L</div>
              {leftImages.length > 0 ? (
                <>
                <img src={imageList[0]?.data_url} alt="" width="100" />
                <img className={styles.remove} src="/assets/cancel.svg" alt="remove" onClick={() => onImageRemove(0)}/>
                </>
              ) : (
                <label
                  htmlFor="upload-button"
                  className={styles.fileIcon}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <img alt="img" src="/assets/paper-upload.svg" />
                </label>
              )}
            </div>
          )}
        </ImageUploading>
        {/*  */}
        <div className={styles.separator}></div>
        {/*  */}
        <ImageUploading
          multiple
          value={rightImages}
          onChange={onRightChange}
          maxNumber={1}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className={styles.previewDiv}>
              <div className={styles.side}>R</div>
              {rightImages.length > 0 ? (
                <>
                <img src={imageList[0]?.data_url} alt="" width="100" />
                <img className={styles.remove} src="/assets/cancel.svg" alt="remove" onClick={() => onImageRemove(0)}/>
                </>
              ) : (
                <label
                  htmlFor="upload-r"
                  className={styles.fileIcon}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <img alt="img" src="/assets/paper-upload.svg" />
                </label>
              )}
            </div>
          )}
        </ImageUploading>
        {/*  */}
        {/* <div className={styles.previewDiv}>
          <div className={styles.side}>L</div>
          <label htmlFor="upload-button" className={styles.fileIcon}>
            <img alt="img" src="/assets/paper-upload.svg" />
          </label>
          <input
            type="file"
            id="upload-l"
            style={{ display: "none" }}
            onChange={handleLeftImg}
          />
        </div>
        <div className={styles.separator}></div>
        <div className={styles.previewDiv}>
          <div className={styles.side}>R</div>
          <div className={styles.fileIcon}>
            <img alt="img" src="/assets/paper-upload.svg" />
          </div>
        </div> */}
      </div>
    </div>
  );
}
